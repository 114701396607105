import React, {useState} from 'react';
import {
    AppBar,
    CircularProgress,
    Container,
    CssBaseline,
    makeStyles,
    Toolbar,
    Typography,
    useScrollTrigger
} from "@material-ui/core";
import '@fontsource/roboto';
import axios from "axios";
import ImageUploader from "react-images-upload";
import * as qs from "qs";
import imageCompression from 'browser-image-compression';

interface Props {
    window?: () => Window;
    children: React.ReactElement;
}

interface HomeProps {
    setErrorMsg: any,
    sessionId: string | undefined,
    roundId: string | undefined
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    font: {

    }
}));



function ElevationScroll(props: Props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const MAX_IMAGE_SIZE = 9999999
/* ENTER YOUR ENDPOINT HERE */
const API_ENDPOINT = process.env.API_ENDPOINT || 'https://ll8rz1j0df.execute-api.us-west-2.amazonaws.com';

const getApiUrl = (link, sessionId, roundId): string => {
    return `${API_ENDPOINT}/${link}?` +
        `round_id=${roundId}&` +
        `session_id=${sessionId}`;
}


function Home(props: HomeProps) {
    const {setErrorMsg, sessionId, roundId} = props;
    const [src, setSrc] = useState<string|undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const instructionMessage = "Click Upload Image, and select the image you want to use for I Spy.";
    const [message, setMessage] = useState<string>(instructionMessage);
    const onChange = async (pictureFiles, pictureDataUrls) => {
        setLoading(true);
        const imageFile: File = pictureFiles[0];
        if (imageFile) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            let compressedFile: File | undefined = undefined;
            try {
                compressedFile = await imageCompression(imageFile, options);
            } catch (error) {
                setLoading(false);
                setErrorMsg(error);
                return;
            }
            const apiCall = getApiUrl('upload_link', sessionId, roundId);
            const response = await axios({
                method: 'GET',
                url: apiCall
            });
            if (response.data.error) {
                setLoading(false);
                setErrorMsg(response.data.error);
                return;
            }
            const uploadUrl = response.data.link;
            if (!uploadUrl) {
                setLoading(false);
                return;
            }
            let buffer: ArrayBuffer = await compressedFile.arrayBuffer();
            let blobData = new Blob([buffer], {type: 'image/jpeg'});
            await fetch(uploadUrl, {
                method: 'PUT',
                body: blobData
            });
            const checkAppropriateApiCall = getApiUrl('check_is_appropriate', sessionId, roundId);
            const checkAppropriateResponse = await axios({
                method: 'GET',
                url: checkAppropriateApiCall
            });
            if (checkAppropriateResponse && checkAppropriateResponse.data)  {
                if (checkAppropriateResponse.data.error) {
                    setMessage(checkAppropriateResponse.data.error);
                }
                else {
                    setSrc(pictureDataUrls[0]);
                }
            }
            else {
                setMessage('Sorry, an Error Occurred');
            }
        }
        setLoading(false);
    };
    return (
        <div>
            {src ?
                <div>
                    <Typography style={{textAlign: 'center'}}>
                        Tell Alexa "Let's Go" when you are ready to play!
                    </Typography>
                    {
                        !loading ?
                            <img
                                src={src} style={{textAlign: 'center', display: 'block', justifyContent: 'center',
                                alignItems: 'center', margin: 'auto', marginBottom: 10, maxWidth: '100%'}}
                                alt={'Inputted Image from User'}/> : <></>
                    }
                </div>
                :
                <>
                    {!loading ?
                        <Typography style={{textAlign: 'center'}}>
                            {message}
                        </Typography> :<></>
                    }
                </>}
            {
                loading ? <>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </div>
                </>: <></>

            }
            <ImageUploader
                withIcon={true}
                buttonText="Upload image"
                singleImage={true}
                withLabel={false}
                onChange={onChange}
                maxFileSize={MAX_IMAGE_SIZE}
            />
        </div>
    )
}


function App(props) {
    const classes = useStyles();
    const { round_id, session_id } = qs.parse(props.location.search.substring(1));
    const [errorMsg, setErrorMsg] = useState<string | null>('');
    const [loading, setLoading] = useState<boolean>(true);

    const apiCall = getApiUrl('validate', session_id, round_id);
    axios({method: 'GET', url: apiCall}).then((response) => {
        let errorMsg : string | undefined | null = response.data.error;
        errorMsg = errorMsg === undefined ? null: errorMsg;
        setLoading(false);
        setErrorMsg(errorMsg);
    });
    return (
        <React.Fragment>
            <CssBaseline/>
            <ElevationScroll>
                <AppBar>
                    <Toolbar>
                        <Typography variant={"h6"} className={classes.title}>
                            ISpy Web App
                        </Typography>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar/>
            <Container style={{maxWidth: 1080, margin: '0 auto', marginTop: 5}}>
                {loading ?
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </div>
                    :
                    <>
                        {errorMsg !== null ?
                            <Typography variant={"h6"} style={{textAlign: 'center'}}>
                                {errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1)}
                            </Typography>
                            :
                            <Home
                                sessionId={session_id}
                                roundId={round_id}
                                setErrorMsg={setErrorMsg}
                            />
                        }
                    </>
                }
            </Container>
        </React.Fragment>
    );
}

export default App;
